const recipeData = [
    {
        id: 16,
        title: 'Seared Sablefish with Lemon Risotto',
        author: 'The Fisherman\'s Market',
        date: 'October 17, 2024',
        type: ['Sablefish'],
        prepTime: '20 min',
        prepTimeValue: 20,
        cookTime: '60 min',
        cookTimeValue: 60,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: 'For the Risotto:',
                list: [
                    '2 tbsp butter or oil',
                    '1 cup white onion, finely diced',
                    '2 cups arborio rice',
                    '1 cup white wine',
                    '4-5 cups hot water or stock',
                    '1 1/2 tsp salt (less if using stock)',
                    '1/4 tsp white pepper',
                    '2 Meyer lemons (juice and zest)',
                ]
            },
            {
                id: 2,
                subTitle: 'For the Sablefish:',
                list: [
                    '4 x 6oz Fisherman’s Market sablefish portions',
                    'Salt and pepper to taste',
                    'Olive oil (for cooking)',
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: 'The Risotto',
                list: [
                    'Sauté the Onions: In a medium heavy-bottomed pot, melt butter over medium heat. Add the diced onions and sauté until tender, about 5-7 minutes.',
                    'Cook the Rice: Add the arborio rice and sauté for 5 minutes, stirring often with a wooden spoon.',
                    'Add Wine: Pour in the white wine, reduce heat to medium-low, and stir until fully absorbed, about 5 minutes.',
                    'Cook with Stock/Water: Gradually add 1/2 cup of hot water or stock at a time, stirring continuously. Wait until each addition is absorbed before adding more, using a total of about 4 cups. Cook for 15-20 minutes until the rice is al dente.',
                    'Finish with Lemon: Add the salt, white pepper, the zest of two lemons, and the juice of 1 1/2 lemons. Taste and adjust seasoning as needed. Cover and set aside until ready to serve.',
                ]
            },
            {
                id: 2,
                subTitle: 'The Fish',
                list: [
                    'Season the Sablefish: Blot the sablefish dry, then season both sides with salt and pepper.',
                    'Sear the Fish: Heat a skillet with olive oil over medium heat. Place the fish skin-side down in the skillet. After a couple of minutes, give the skillet a shake to loosen the fish.',
                    'Cook the Fish: Turn the heat to medium, and continue searing the skin side for 5-7 minutes, until the skin is crispy. Turn the fish over and reduce the heat to medium-low, cooking for another 4-6 minutes.',
                    'Serve: Divide the risotto among plates, then top each with a portion of seared sablefish, skin-side up.',
                ]
            }
        ],
        notes: {
            description: '',
            chef: ''
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_seared_sablefish_with_lemon_risotto.webp'
    },
    {
        id: 15,
        title: 'Soy-Ginger Glazed Sablefish',
        author: 'The Fisherman\'s Market',
        date: 'October 17, 2024',
        type: ['Sablefish'],
        prepTime: '5 min',
        prepTimeValue: 5,
        cookTime: '15 min',
        cookTimeValue: 15,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '4 x 6oz Fisherman’s Market sablefish portions',
                    '1/2 cup sushi soy sauce',
                    '1/2 cup plum sake',
                    '1/3 cup white sugar',
                    '1 tbsp sesame oil',
                    '1 clove garlic, minced',
                    '2 tbsp ginger, minced',
                    'Finely chopped green onions (for garnish)',
                    'Toasted sesame seeds (for garnish)',
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Prepare Sauce: Mix all ingredients (except the fish) in a saucepan. Bring the mixture to a boil, then simmer until reduced by half and thickened.',
                    'Broil Sablefish: Brush the sauce over the sablefish portions and broil for 3 minutes. Remove from the oven, brush again, and repeat 2 more times for a total cook time of 9-12 minutes.',
                    'Garnish: Top the cooked sablefish with finely chopped green onions and toasted sesame seeds before serving.',
                ]
            }
        ],
        notes: {
            description: '',
            chef: ''
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_soy_ginger_glazed_sablefish.webp'
    },
    {
        id: 14,
        title: 'Tanner Crab Seafood Boil',
        author: 'The Fisherman\'s Market',
        date: 'October 17, 2024',
        type: ['Crab'],
        prepTime: '10 min',
        prepTimeValue: 10,
        cookTime: '20 min',
        cookTimeValue: 20,
        serves: '6 (8-10 with additional seafood)',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '3 lemons, divided use',
                    '1/2 cup Old Bay seasoning (plus more for garnish)',
                    '8 cloves garlic, smashed',
                    '1 onion, peeled and cut into 6 pieces',
                    '1 pound small Yukon gold potatoes, halved',
                    '4 ears corn on the cob, cut into 3-4 inch pieces',
                    '3 pounds Fisherman\'s Market Alaskan Tanner crab legs',
                    '1 pound smoked sausage (kielbasa or andouille), cut into 1-inch pieces',
                    '3 tablespoons butter',
                    '2 tablespoons chopped parsley',
                ]
            },
            {
                id: 2,
                subTitle: 'Additional Fisherman’s Seafood (optional):',
                list: [
                    'Lobster tails (4-6 oz each): 6 minutes',
                    'Clams or mussels: 4 minutes',
                    'Tiger prawns, peeled: 3 minutes',
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Prepare Lemons: Cut 2 lemons into quarters. Slice the remaining lemon into wedges and set aside for garnish.',
                    'Boil Water: Fill a large pot with 14-16 cups of water. Add the quartered lemons, Old Bay seasoning, garlic, and onion to the water. Bring to a boil.',
                    'Cook Potatoes: Add the potatoes to the pot and cook for 8 minutes.',
                    'Add Seafood: Add lobster tails and cook for 5 minutes; Add clams, crab legs, and corn, and cook for 6 minutes; Add shrimp and sausage, and cook for 2-3 minutes.',
                    'Drain and Reserve Broth: Drain the seafood mixture, reserving 1 cup of the broth.',
                    'Make Butter Broth: Melt the butter in a small bowl and whisk it into the reserved broth. Pour the broth over the seafood mixture.',
                    'Garnish and Serve: Garnish with chopped parsley and lemon wedges. Sprinkle with additional Old Bay seasoning to taste. Serve immediately.',
                ]
            }
        ],
        notes: {
            description: '',
            chef: ''
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_tanner_crab_seafood_boil.webp'
    },
    {
        id: 13,
        title: 'Teriyaki Blackened Salmon',
        author: 'The Fisherman\'s Market',
        date: 'September 28, 2024',
        type: ['Salmon'],
        prepTime: '10 min',
        prepTimeValue: 10,
        cookTime: '15 min',
        cookTimeValue: 15,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '1 fillet of salmon (1.75 to 2 lbs)',
                    '1/4 cup tamari (or low-sodium soy sauce)',
                    '1/4 cup water',
                    '1/4 cup brown sugar',
                    '2 tbsp honey',
                    '1 tsp paprika',
                    '1 tsp fresh ginger (or 1/2 tsp ground ginger)',
                    '1 tsp diced garlic (or 1/2 tsp garlic powder)',
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Preheat your oven to 375°F.',
                    'Place the salmon fillet skin-side down on a parchment-lined baking tray.',
                    'In a small bowl, mix together all the sauce ingredients. Use a pastry brush to coat the fillet thoroughly with the sauce. Be sure not to leave too much excess sauce on the pan as the brown sugar may burn.',
                    'Bake the salmon for 10 minutes, then remove from the oven.',
                    'Preheat your broiler to 450°F. Once preheated, place the salmon under the broiler for 2-3 minutes, watching closely to avoid burning.',
                    'Remove the salmon from the oven. Serve with your choice of vegetables or shred it into rice.',
                ]
            }
        ],
        notes: {
            description: '',
            chef: ''
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_teriyaki_blackened_sockeye_salmon.webp'
    },
    {
        id: 12,
        title: 'Seared Lemon Garlic Butter Scallops',
        author: 'The Fisherman\'s Market',
        date: 'September 28, 2024',
        type: ['Scallops'],
        prepTime: '5 min',
        prepTimeValue: 5,
        cookTime: '5 min',
        cookTimeValue: 5,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '1 ½ pounds of high-quality Fisherman’s Market scallops',
                    '2 tbsp olive oil',
                    '3 tbsp unsalted butter, divided',
                    '4-5 large garlic cloves, minced (or 1 ½ tbsp minced garlic)',
                    'Salt and fresh ground black pepper to taste',
                    '¼ cup dry white wine or broth',
                    '2 tbsp lemon juice',
                    '¼ cup chopped parsley',
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'If scallops are frozen, thaw in cold water. Remove the side muscle if attached. Pat scallops thoroughly dry with paper towels.',
                    'Heat olive oil in a large pan or skillet over medium-high heat until hot and sizzling. Add scallops in a single layer without overcrowding the pan (work in batches if needed). Season with salt and pepper to taste. Fry for 2-3 minutes on one side until a golden crust forms, then flip and fry for another 2 minutes until crisp, lightly browned, and cooked through (opaque). Remove from skillet and transfer to a plate.',
                    'In the same pan, melt 2 tablespoons of butter, scraping up any browned bits left from the scallops. Add minced garlic and cook for 1 minute until fragrant.',
                    'Pour in wine or broth and simmer for 2 minutes, or until reduced by about half. Stir in the remaining tablespoon of butter and lemon juice.',
                    'Remove the pan from heat and add the scallops back to the pan to warm through. Garnish with chopped parsley.',
                    'Serve with rice, pasta, garlic bread, or steamed vegetables (cauliflower, broccoli, or zucchini noodles).',
                ]
            }
        ],
        notes: {
            description: '',
            chef: ''
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_lemon_garlic_sauce_seared_scallops.webp'
    },
    {
        id: 11,
        title: 'Mediterranean Baked Ling Cod',
        author: 'The Fisherman\'s Market',
        date: 'September 28, 2024',
        type: ['Ling Cod'],
        prepTime: '10 min',
        prepTimeValue: 10,
        cookTime: '20 min',
        cookTimeValue: 20,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '1 ½ pounds Fisherman’s market ling cod fillet (skin on or off, your choice)',
                    '1 small red onion OR large shallot, sliced',
                    '3 cloves garlic, crushed',
                    '1 pound cherry tomatoes, halved',
                    '¼ cup sliced black olives',
                    '1 tablespoon olive oil',
                    '1 teaspoon Italian seasoning',
                    'Salt & pepper to taste',
                    '¼ cup white wine OR water',
                    '4 tablespoons butter, chopped',
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Prep: Preheat the oven to 400°F. Lightly grease a rectangular baking dish that will comfortably hold all your ling cod pieces and tomatoes, and place the fish inside.',
                    'Add vegetables: Scatter the onion, garlic, tomatoes, and olives around the fish.',
                    'Season: Drizzle the olive oil over all the ingredients in the dish. Sprinkle with Italian seasoning and season with salt and pepper.',
                    'Finish and bake: Pour the water or wine into the dish. Scatter the butter evenly over the top. Bake for 15-18 minutes, or until the tomatoes and fish are cooked through.',
                ]
            }
        ],
        notes: {
            description: '',
            chef: ''
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_mediterranean_baked_ling_cod.webp'
    },
    {
        id: 10,
        title: 'Sesame-Crusted Tuna Tataki',
        author: 'The Fisherman\'s Market',
        date: 'September 28, 2024',
        type: ['Tuna'],
        prepTime: '5 min',
        prepTimeValue: 5,
        cookTime: '2 min',
        cookTimeValue: 2,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '2 x 6oz Fishrman’s Market ahi tuna steaks',
                    '1/2 cup sesame seeds',
                    '3 tbsp oil for frying (peanut, canola, or vegetable)',
                    'Salt and pepper to taste',
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Pat the ahi tuna steaks dry and lightly season them with salt and pepper on all sides.',
                    'Spread the sesame seeds on a flat plate. Coat all sides of the tuna steaks in the sesame seeds, pressing them gently to adhere.',
                    'In a saucepan, heat the oil over medium-high heat until it just begins to smoke.',
                    'Carefully place the coated tuna steaks into the pan and sear for about 30 seconds on each side, or until golden.',
                    'Let the tuna rest for 2 minutes, then slice thinly and serve.',
                ]
            }
        ],
        notes: {
            description: '',
            chef: ''
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_seasame_crusted_ahi_tuna_tataki.webp'
    },
    {
        id: 9,
        title: 'Pan-Seared Halibut with Lemon Caper Sauce',
        author: 'The Fisherman\'s Market',
        date: 'September 25, 2024',
        type: ['Halibut'],
        prepTime: '10 min',
        prepTimeValue: 10,
        cookTime: '12 min',
        cookTimeValue: 12,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '4 x 6 oz portions Fisherman’s Market halibut (skin off)',
                    '4 tablespoons butter, divided',
                    '1 tablespoon extra-virgin olive oil',
                    '1/2 teaspoon kosher salt',
                    'Few grinds black pepper',
                    '2 teaspoons fresh garlic, finely chopped',
                    '1/4 cup white wine (such as Chardonnay)',
                    '2 tablespoons lemon juice',
                    '2 tablespoons capers, drained',
                    '2 tablespoons fresh flat-leaf parsley, chopped',
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Season the halibut with salt and pepper on both sides. Place the fish in a medium-high heat pan with olive oil. Sear the fish for 4 minutes on each side.\nCheck for doneness by poking the fish; it should flake easily. You want to stop cooking just before it fully cooks, so a little undercooked in the center is fine, as it will continue to cook outside of the pan.',
                    'Using one or two fish turners, carefully remove the fish fillets to a platter.',
                    'Keep the heat at medium and add the garlic to the pan. Cook for one minute until fragrant. Add the white wine and cook to evaporate.',
                    'Stir in the lemon juice, capers, and parsley. Cook for an additional minute, then remove from heat and stir in the 4 tablespoons of butter to make the sauce.',
                    'Return the fish to the pan along with any liquid from the platter. Bring the heat back to medium and use a spoon to drizzle the sauce over the fish. Cook for 30 seconds, then remove to a platter and serve.',
                ]
            }
        ],
        notes: {
            description: '',
            chef: ''
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_lemon_caper_sauce_halibut.webp'
    },
    {
        id: 1,
        title: 'Mediterranean halibut cheeks',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'February 6, 2023',
        type: ['Halibut'],
        prepTime: '10 min + 1hr marinate',
        prepTimeValue: 70,
        cookTime: '40 min',
        cookTimeValue: 40,
        serves: '2',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '1lb of halibut cheeks from your local Fisherman’s Market, thawed',
                    '1 container of grape tomatoes, halved',
                    '1 can medium pitted black olives, drained',
                    '1 medium red onion, diced',
                    '½ cup tricolour quinoa, cooked',
                    '2 tbsp feta cheese, crumbled'
                ]
            },
            {
                id: 2,
                subTitle: 'Marinade',
                list: [
                    '¼ cup olive oil',
                    '2tbsp fresh parsley',
                    '1tsp minced garlic (or ½ tsp garlic powder)',
                    '2 tsp  lemon pepper',
                    'salt to preference'
                ]
            },
            {
                id: 3,
                subTitle: 'Quinoa dressing',
                list: [
                    '1 tbsp olive oil',
                    '1 tsp dried oregano',
                    '1 tsp minced garlic',
                    '1 tsp lemon pepper',
                    '1 tbsp feta cheese'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Combine your marinade and coat halibut cheeks, saving some for your veggies, and let marinate in the fridge for at least an hour.',
                    'Toss tomatoes, olives and red onion in the rest of the marinade and roast in a 400°F oven for 30  minutes.',
                    'While veggies are roasting, cook quinoa as per package instructions. Make up your dressing in a  small bowl and add warmed quinoa once done.',
                    'On the stove get a dry nonstick pan warmed to medium-high. Without crowding the pan, place your halibut cheeks into the pan and cook for 3 minutes, flip, cook for 2 minutes and then remove from heat.',
                    'To plate: Add quinoa and veggies to the center of the plate, and top with feta cheese. Top with your halibut cheeks, and then garnish with a drizzle of olive oil and a sprinkle of parsley.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Jessi, Kelowna store manager, 2023.',
            chef: 'Chef Jessi'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_mediterranean_halibut_cheeks_recipe-scaled.webp'
    },
    {
        id: 2,
        title: 'Brown butter pickerel',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'January 30, 2023',
        type: ['Pickerel'],
        prepTime: '3 min',
        prepTimeValue: 3,
        cookTime: '6 min',
        cookTimeValue: 6,
        serves: '2',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '500g pickerel from your local Fisherman’s Market',
                    '6 tbsp butter',
                    '3 tbsp oil',
                    'Salt & pepper to taste'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Season pickerel with salt and pepper to taste',
                    'Start saucepan with 3 tbsp oil and 2 tbsp butter',
                    'Bring to medium/high heat',
                    'Add pickerel once the butter starts to bubble',
                    'Cook for 2-3 minutes, then flip',
                    'Add 4 tbs butter',
                    'Continue cooking for an additional 2-3 minutes while basting or spooning the butter over the pickerel (as it cooks, the butter will turn brown in colour due to the solids cooking and darkening, which will leave you with a wonderful nutty aroma)',
                    'Add this to any pasta or salad!'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Ty Gordon, Fisherman’s Market Burnaby store manager, 2023.',
            chef: 'Chef Mike'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_ChefTy_brown_butter_pickerel_recipe-scaled.webp'
    },
    {
        id: 3,
        title: 'Smoked salmon scallops',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'January 9, 2023',
        type: ['Salmon', 'Scallops'],
        prepTime: '20 min',
        prepTimeValue: 20,
        cookTime: '5 min',
        cookTimeValue: 5,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '12 x 10/20 scallops from your local Fisherman’s Market',
                    '12 slices smoked salmon from your local Fisherman’s Market'
                ]
            },
            {
                id: 2,
                subTitle: 'Crème Fraîche',
                list: [
                    '½ cup crème fraîche',
                    '1 tsp lemon zest',
                    '1.5 tsp finely diced chives',
                    '1 tbsp Ethical Table Canadian Seas (substitute Canadian Seas for fresh dill)',
                    '1 tsp lemon juice',
                    'Salt & pepper to taste'
                ]
            },
            {
                id: 3,
                subTitle: 'Garnish (optional)',
                list: [
                    'Lemon zest',
                    'Edible flowers',
                    'Fresh dill',
                    'Thinly sliced watermelon radish'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Make crème fraîche at least two hours ahead of time and let chill in the fridge.',
                    'Pat scallops dry with a paper towel.',
                    'Roll out smoked salmon, slice lengthwise to fit the height of the scallop and wrap the scallop gently.',
                    'Sear the scallops for approximately two minutes until you have a golden brown sear. Flip the scallops and place the pan in the oven for two mins to cook the scallops medium rare. Rest scallops for two mins out of the pan before plating.',
                    'Plate by adding crème fraîche and the scallops, and finish with the garnish.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Mike from the Ethical Table.',
            chef: 'Chef Mike'
        },
        videoUrl: 'https://www.instagram.com/reel/CnNEZIiO1CQ/?igshid=YmMyMTA2M2Y=',
        imgUrl: '/images/recipes/FishermansMarket_ChefMike_smoked_salmon_scallops_recipe-scaled.webp'
    },
    {
        id: 4,
        title: 'Jambalaya soup',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'December 28, 2022',
        type: ['Prawns'],
        prepTime: '15 min',
        prepTimeValue: 15,
        cookTime: '30 min',
        cookTimeValue: 30,
        serves: '8',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '1lb peeled, deveined, tail-off prawns (41/50 size  or 31/40 size work best) from your local Fisherman’s Market',
                    '1lb andouille sausage, sliced into rounds',
                    '3 strips thick sliced bacon, diced',
                    '1 tbsp butter or olive oil',
                    '1 red onion, large diced',
                    '1 white onion, large diced',
                    '2 bell pepper, large diced',
                    '4 stalks of celery, thin sliced',
                    '6 garlic cloves, minced',
                    '1 jalapeno, thin sliced',
                    '1x 15oz can diced tomatoes in water',
                    '1x 15oz can black beans',
                    '1 cup whole-kernel corn',
                    '1L chicken stock + 1L water',
                    '1 tbsp Old Bay seasoning + 1 tsp separated',
                    '1 tbsp Cajun seasoning',
                    '2 bay leaves',
                    'Salt & pepper'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Prep your veggies: onions, peppers, celery and jalapeno can be added to a bowl together. Minced garlic separate. Rinse and drain beans, and have your diced tomato can open (you want the liquid added to the soup so do not drain) and corn measured.',
                    'In a large saucepan or stock pot, heat to medium and add in diced bacon and sausage. Cook until the bacon is crispy and the sausage rounds are browning, remove from the pot with a slotted spoon, leaving the bacon fat behind and add in butter. Add garlic to the pot and sauté for one minute, before adding the bowl of veggies and sautéing until onions become semi-transparent. Add in your 1 tbsp Old Bay and Cajun seasoning and stir well to bloom flavours.',
                    'Add in the can of diced tomatoes, can of black beans, cup of corn, chicken stock, water and bay leaves. Allow to come to a simmer and cook for 15 minutes until veggies are soft.',
                    'In a separate frying pan heated to medium-high, add prawns with 1 tsp Old Bay and a little olive oil. Cook until prawns are opaque and firm, and then spoon them into the soup, adding back in the sausage and bacon at the same time.',
                    'Keeps well in the fridge for up to 4 days.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Jessi, Kelowna store manager, 2022.',
            chef: 'Chef Jessi'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_ChefJessi_Jambalaya_soup_recipe-scaled.webp'
    },
    {
        id: 5,
        title: 'Spicy ahi tuna on crispy rice',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'December 28, 2022',
        type: ['Tuna'],
        prepTime: '15 min',
        prepTimeValue: 15,
        cookTime: '10 min',
        cookTimeValue: 10,
        serves: '2',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '2 x 4oz ahi tuna steaks from your local Fisherman’s Market, semi-thawed (it is easier to slice if sort of frozen, though fully thawed is fine with a sharp knife)',
                    '1 cup of cooked sushi rice',
                    '1 tsp mirin',
                    '1 tsp rice vinegar',
                    '1 tsp black sesame seeds',
                    'Oil for pan frying'
                ]
            },
            {
                id: 2,
                subTitle: 'For Honey-Sriracha drizzle',
                list: [
                    '2 tbsp honey',
                    '1 tsp light soy sauce',
                    '1 tsp sriracha',
                    'Pinch of chilli flakes',
                    'Pinch of black sesame seeds',
                    '1 jalapeno, thinly sliced and rinsed to remove seeds'
                ]
            },
            {
                id: 3,
                subTitle: 'Special equipment',
                list: [
                    'Rice cooker or stove',
                    '6 x 9 loaf pan/8×8 cake pan or similar to form rice into a rectangle or square.'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Preparing the rice: add the rice to a bowl with cold water and agitate with your hands to rinse off as much starch as possible, strain in a sieve and repeat 2-3 times until the water runs clear.',
                    'Rice cooker: cook according to instructions. On a stove: add 1 cup rinsed rice and 1 cup fresh water to a small pot on the stove at medium heat until water begins to gently boil, then lid tightly and reduce heat to low and cook for 15 minutes.',
                    'Once the rice is cooked, add in mirin, vinegar and sesame seeds and fluff rice with a fork gently so as not to mash the grains. Transfer to a loaf pan lined with cling film/parchment paper (the rice will be sticky so running cold water on your utensils will help with sticking). Press into an even layer gently (about 2cm thick), you want to form the rectangular shape without crushing the rice as much as possible. Once pressed into a flat rectangle, place it in the fridge to firm up.',
                    'Prepare the sauce: mix all ingredients together in a small bowl and set aside.',
                    'Prepare the tuna: with a very sharp knife, cut your tuna into thin slices, and place it in the fridge while you fry your rice.',
                    'Remove the rice from the fridge and lift it from the pan in the cling film. With a wet knife, cut into rectangles that match the size of your tuna slices.',
                    'Heat oil in a small frying pan until a grain of rice dropped in the oil starts to bubble quickly. Fry your rice rectangles a few at a time, flipping when golden.',
                    'To assemble: place your crispy rice on a plate, and top it with slices of tuna and a spoonful of sauce. Finish the plate with a small drizzle of sauce all around and serve.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Jessi, Kelowna store manager, 2022.',
            chef: 'Chef Jessi'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_ChefJessi_ahi_tuna_on_crispy_rice_recipe-scaled.webp'
    },
    {
        id: 6,
        title: 'Halibut & burrata sandwich',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'December 21, 2022',
        type: ['Halibut'],
        prepTime: '10 min',
        prepTimeValue: 10,
        cookTime: '8 min',
        cookTimeValue: 8,
        serves: '2',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '1lb halibut cubes, no skin, from your local Fisherman’s Market',
                    '1 burrata',
                    '6 strips of prosciutto',
                    'Arugula (as much as you’d like)',
                    '2 Portuguese buns (or equivalent)',
                    'Balsamic reduction/glaze (for drizzling)',
                    'Olive oil (for drizzling )',
                    '2 Rosemary twigs (chopped)',
                    '5 tbsp Jerry’s Faves No Bull',
                    'Salt & pepper to taste',
                    '1/4 cup cornstarch',
                    '1/4 cup flour',
                    '1/2 cup oil for frying',
                    '3 tbsp mayo (to spread on buns)',
                    '2 tbsp of butter (to toast buns)'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Pat the dry halibut, and cut it into 1.5-1 inch cubes.',
                    'Season the halibut with salt, pepper, rosemary and Jerry’s Faves No Bull.',
                    'Combine the starch, flour and rosemary in a mixing bowl.',
                    'Toss the halibut in the starch and flour mixture until evenly coated.',
                    'Preheat the skillet with oil to medium heat and fry the halibut, turning every 1.5 minutes. Cook for 3-5 minutes.',
                    'Place the halibut on the toasted buns with mayo and the rest of the ingredients to your preference.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Chef Ty Gordon, Fisherman’s Market Burnaby store manager, 2022.',
            chef: 'Chef Mike'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_halibut_burrata_sandwich_recipe-scaled.webp'
    },
    {
        id: 7,
        title: 'Sesame-crusted tuna tataki',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'October 28, 2022',
        type: ['Tuna'],
        prepTime: '5 min',
        prepTimeValue: 5,
        cookTime: '2 min',
        cookTimeValue: 2,
        serves: '4',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '2 x 6oz ahi tuna steaks from your local Fisherman’s Market',
                    '½ cup of sesame seeds',
                    '3 tbsp oil for frying (peanut, canola, vegetable)',
                    'Salt & pepper to taste'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'Pat the ahi tuna steaks dry and then lightly season them with salt and pepper on all sides.',
                    'Place the sesame seeds on a flat plate, take the ahi tuna steaks and coat all sides in the sesame seeds.',
                    'In a saucepan, heat the oil on medium-high heat until the oil slightly smokes.',
                    'Place coated ahi tuna steaks in a pan and let sear for 30 seconds (or until golden) on each side.',
                    'Let sit for 2 minutes then slice thinly and serve.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Tyrone Gordon, Fisherman’s Market Burnaby store manager.',
            chef: 'Tyrone Gordon'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_sesame_crusted_tuna_tataki_recipe-scaled.webp'
    },
    {
        id: 8,
        title: 'Scallop and Pappardelle pasta with mushrooms and goat cheese',
        author: 'Chef Jessi & Chef Ty Recipes',
        date: 'October 28, 2022',
        type: ['Scallops'],
        prepTime: '15 min',
        prepTimeValue: 15,
        cookTime: '30 min',
        cookTimeValue: 30,
        serves: '2',
        ingredients: [
            {
                id: 1,
                subTitle: '',
                list: [
                    '8 10/20 Hokkaido scallops from your local Fisherman’s Market (other sizes and kinds will work as well)',
                    '3 nests of Pappardelle',
                    '1 egg yolk',
                    '100g semi-soft goat cheese (can add more or less based on your preference)',
                    '2 portobello mushrooms',
                    '2 tbsp oil (vegetable or other oil)',
                    '4 tbsp butter (1 tbsp separated)',
                    '2 cloves of garlic (minced)',
                    '2 tbsp salt',
                    'Salt & pepper to taste'
                ]
            }
        ],
        directions: [
            {
                id: 1,
                subTitle: '',
                list: [
                    'In a medium-sized pot, bring 6 cups of water and 2 tablespoons salt to a boil, then add pasta and simmer until pasta is al dente (about 10-12 minutes). Set aside 1/4 cup of pasta water and strain the rest.',
                    'While pasta is cooking, heat a saucepan to medium heat, add 1 tbsp oil then mushrooms and garlic and cook until mushrooms are dark and cooked through. Then add goat cheese and 3 tbsp of butter, reduce heat and mix until combined.',
                    'Add the cooked pasta to the mushrooms and goat cheese and stir in 1/4 cup of pasta water and let simmer. Stir until everything is combined.',
                    'Pat the scallops dry and generously sprinkle salt on top.',
                    'Heat a small pan on high with 1 tbsp of oil until it starts to smoke, add scallops salt side down in a circle formation, then sprinkle salt on the top (place scallops between 1 1/2cm to 2cm apart from each other).',
                    'Going around the circle, flip each scallop after 1 minute or when golden brown on the bottom. Cook for 30 seconds then add 1 tbsp of butter, baste over the scallops for an additional 30 seconds then set aside to rest for 30 seconds.',
                    'Plate the pasta and add the scallops on top.'
                ]
            }
        ],
        notes: {
            description: 'This recipe was created by Tyrone Gordon, Fisherman’s Market Burnaby store manager.',
            chef: 'Tyrone Gordon'
        },
        videoUrl: '',
        imgUrl: '/images/recipes/FishermansMarket_scallop_and_pappardelle_pasta_with_mushrooms_and_goat_cheese_recipe-scaled.webp'
    }
];

export default recipeData;